import {
  CloudUploadOutlined,
  DeleteOutlined,
  OrderedListOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Col, Dropdown, FloatButton, message, Row, Tabs } from "antd";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TABLES } from "../../_CONSTANTS/constants";
import { createIcosXMLFromDocumentsArray } from "../../api/Download/createXml";
import TableDocumentsIco from "../../components/documents/incoming-cash-orders/list/TableDocumentsIco";
import ExportModal from "../../components/export/ExportModal";
import BackTo from "../../components/global/BackTo";
import {
  deleteRecord,
  getAllRecords,
  getRecordByKeyValue,
  getRecords,
  updateRecords,
} from "../../db/dexie-db/functions";
import {
  filterNotExportedDocuments,
  getFormattedDocuments,
} from "../../db/document-functions/document-functions";
dayjs.extend(isBetween);

const IcoPage = () => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalExportOpen, setIsModalExportOpen] = useState(false);
  const [documentsToExport, setDocumentsToExport] = useState([]);
  const [exportSuccess, setIsExportSuccess] = useState();

  useEffect(() => {
    const getDataBefore = async () => {
      const icos = await getAllRecords("Ico");
      try {
        const icosToTable = await Promise.all(
          icos.map(async (ico, index) => {
            const client = await getRecordByKeyValue(TABLES.CLIENTS, {
              guid: ico.clientGuid,
            });
            if (!client) {
              ico.clientName = "";
            } else {
              ico.clientName = client.name;
            }
            ico.key = ico.id;
            ico.index = index + 1;
            return ico;
          })
        );
        setDocuments(icosToTable);
      } catch (error) {
        console.log(error);
        message.error("Помилка отримання документів");
      }
      setLoading(false);
    };

    getDataBefore();
  }, []);

  const afterExportFunction = async () => {
    if (!exportSuccess) {
      return;
    }
    message.success("Всі документи успішно відправлено!");
    const exportedDocuments = documentsToExport.map((exportedDoc) => {
      return { ...exportedDoc, exported: "true" };
    });

    const updated = await updateRecords("Ico", exportedDocuments);
    if (!updated) {
      return message.error("Помилка оновлення документів");
    }

    const notExportedDocuments = filterNotExportedDocuments(
      exportedDocuments,
      documents
    );

    const newDocuments = await getFormattedDocuments([
      ...notExportedDocuments,
      ...exportedDocuments,
    ]);

    setDocuments(newDocuments);
  };

  const handleDownloadTodayDocuments = async () => {
    const todayDocuments = await getRecords(
      "Ico",
      "date",
      dayjs().format("DD.MM.YY")
    );
    if (!todayDocuments.length)
      return message.info("Немає даних для відправки");
    createIcosXMLFromDocumentsArray(todayDocuments);
  };

  const handleDownloadUnexported = async () => {
    const unexportedDocuments = await getRecords("Ico", "exported", "false");
    if (!unexportedDocuments.length)
      return message.info("Немає даних для відправки");
    createIcosXMLFromDocumentsArray(unexportedDocuments);
  };

  const handleExportTodayDocuments = async () => {
    const todayDocuments = await getRecords(
      "Ico",
      "date",
      dayjs().format("DD.MM.YY")
    );
    if (!todayDocuments.length)
      return message.info("Немає даних для відправки");
    setDocumentsToExport(todayDocuments);
    setIsModalExportOpen(true);
  };

  const handleExportUnexported = async () => {
    const unexportedDocuments = await getRecords("Ico", "exported", "false");
    if (!unexportedDocuments.length)
      return message.info("Немає даних для відправки");
    setDocumentsToExport(unexportedDocuments);
    setIsModalExportOpen(true);
  };

  const handleDeleteExported = async () => {
    const documentsToDelete = await getRecords("Ico", "exported", "true");

    if (!documentsToDelete.length) {
      return message.info("Немає документів для видалення");
    }

    deleteDocuments(documentsToDelete);
  };

  const deleteDocuments = async (documentsToDelete) => {
    const isSuccessDeleted = await Promise.all(
      documentsToDelete.map(async (doc) => {
        await deleteRecord("Ico", doc.id);
      })
    );

    if (isSuccessDeleted) {
      const newDocuments = documents.filter(
        (document) => document.exported !== "true"
      );
      const formattedDocuments = await getFormattedDocuments(newDocuments);
      setDocuments(formattedDocuments);
      message.success("Відправлені документи видалено!");
    }
  };

  const deftabs = [
    {
      label: (
        <span>
          <OrderedListOutlined />
          ПКО
        </span>
      ),
      key: "1",
      children: <></>,
    },
  ];

  const deleteItems = [
    {
      label: "Видалити відправлені",
      key: "1",
      onClick: handleDeleteExported,
    },
  ];
  const exportItems = [
    {
      label: "Відправити сьогоднішні",
      key: "1",
      onClick: handleExportTodayDocuments,
    },
    {
      label: "Відправити невідправлені",
      key: "2",
      onClick: handleExportUnexported,
    },
    {
      label: "Завантажити сьогоднішні",
      key: "3",
      onClick: handleDownloadTodayDocuments,
    },
    {
      label: "Завантажити невідправлені",
      key: "4",
      onClick: handleDownloadUnexported,
    },
  ];

  const deleteOperations = (
    <Dropdown.Button menu={{ items: deleteItems }}>
      <DeleteOutlined />
    </Dropdown.Button>
  );
  const exportOperations = (
    <Dropdown.Button
      onClick={() => {
        handleExportUnexported();
      }}
      menu={{ items: exportItems }}
    >
      <CloudUploadOutlined />
    </Dropdown.Button>
  );

  const OperationsSlot = {
    left: deleteOperations,
    right: exportOperations,
  };

  return (
    <>
      <Tabs
        tabBarStyle={{ marginBottom: "5px" }}
        centered
        tabBarExtraContent={OperationsSlot}
        defaultActiveKey="1"
        size={"middle"}
        items={deftabs}
      />
      <Row style={{ justifyContent: "center" }}>
        <Col span={24}>
          <TableDocumentsIco
            loading={loading}
            documents={documents}
            setDocuments={setDocuments}
          ></TableDocumentsIco>
          <FloatButton
            size="large"
            type="primary"
            style={{ position: "fixed", bottom: "20px", right: "24px" }}
            onClick={() => navigate("create")}
            icon={<PlusCircleOutlined />}
          ></FloatButton>
        </Col>
        <ExportModal
          setIsExportSuccess={setIsExportSuccess}
          afterExportFunction={afterExportFunction}
          isModalExportOpen={isModalExportOpen}
          setIsModalExportOpen={setIsModalExportOpen}
          documents={documentsToExport}
          documentType={"ico"}
        ></ExportModal>
        <BackTo to={"/"}></BackTo>
      </Row>
    </>
  );
};

export default IcoPage;
