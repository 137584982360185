import { Button, Col, Divider, Form, Image, Input, message, Row } from "antd";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../api/Admin/api";
import { useAuth } from "../hook/useAuth";
// import { Form } from "react-router-dom";

export default function AdminLoginPage() {
  const navigate = useNavigate();

  const { adminUser, signInAdmin } = useAuth();
  const passwordRef = useRef(null);
  const userNameRef = useRef(null);
  const [loading] = useState(false);

  if (adminUser) {
    return navigate("/admin");
  }

  const onFinish = async (values) => {
    const result = await login(values.username, values.password);
    if (result.status === "success") {
      const adminUser = result.user;
      return signInAdmin(adminUser);
    }
    return message.error("Помилка авторизації");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <div className="loginPage">
        <Row>
          {/* <Col span={3}></Col> */}
          <Col offset={3} span={18}>
            <Form
              className="loginForm"
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Image
                style={{ width: "50%" }}
                preview={false}
                src={"/waapp-logo-light.svg"}
              ></Image>
              <Divider style={{ color: "white", border: "white" }}>
                ТА-Admin - Авторизація
              </Divider>
              <Form.Item
                label="Ім'я користувача"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Введіть ім'я користувача",
                  },
                ]}
              >
                <Input
                  ref={userNameRef}
                  size="large"
                  placeholder="Ім'я користувача"
                  allowClear
                />
              </Form.Item>

              <Form.Item
                label="Пароль"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Введіть будь ласка пароль",
                  },
                ]}
              >
                <Input.Password
                  color="white"
                  size="large"
                  placeholder="Пароль"
                  allowClear
                  ref={passwordRef}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loading}
                  style={{ width: "100%", border: "1px solid white" }}
                  size="large"
                  type="primary"
                  htmlType="submit"
                >
                  Вхід
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}
