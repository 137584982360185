import {
  CloudUploadOutlined,
  DeleteOutlined,
  OrderedListOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  FloatButton,
  Input,
  Row,
  Space,
  Table,
  Tabs,
  message,
} from "antd";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TABLES } from "../../_CONSTANTS/constants";
import { createOrdersXMLFromDocumentsArray } from "../../api/Download/createXml";
import ModalCreateIco from "../../components/debts/ModalCreateIco";
import { TableTotals } from "../../components/documents/orders/create/TableTotals";
import ModalActions from "../../components/documents/orders/list/ModalActions";
import ExportModal from "../../components/export/ExportModal";
import { DateFilterDropDown } from "../../components/filters/DateFilterDropDown";
import BackTo from "../../components/global/BackTo";
import {
  deleteRecord,
  getAllRecords,
  getRecords,
  updateRecords,
} from "../../db/dexie-db/functions";
import {
  filterNotExportedDocuments,
  getFormattedDocuments,
} from "../../db/document-functions/document-functions";
import { getOrdersTotals } from "../../utils/table-totals";
dayjs.extend(isBetween);

const onChange = (pagination, filters, sorter, extra) => {
  // console.log("params", pagination, filters, sorter, extra);
};

function Orderspage() {
  let navigate = useNavigate();
  const [isModalActionsOpen, setIsModalActionsOpen] = useState(false);
  // const [setSearchText] = useState("");
  const [isModalExportOpen, setIsModalExportOpen] = useState(false);
  const [documentsToExport, setDocumentsToExport] = useState([]);
  const [exportSuccess, setIsExportSuccess] = useState();
  const [isModalCreateIcoOpen, setIsModalCreateIcoOpen] = useState(false);
  const [document, setDocument] = useState();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    // setSearchText(selectedKeys[0]);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    // setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          size={"large"}
          placeholder={`Введіть текст`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Шукати
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Очистити
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (!record[dataIndex]) return;
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: "№",
      dataIndex: "index",
      key: "index",
      width: "10%",
      sorter: (a, b) => a.index - b.index,
      // defaultSortOrder: "descend",
    },
    {
      title: "Дата",
      dataIndex: "date",
      width: "20%",
      key: "date",
      render: (_, record) => {
        const created = dayjs(record.created.$d).format("DD.MM HH:mm");
        return created;
      },
      sorter: (a, b) => {
        // console.log(dayjs(a.created.$d) - dayjs(b.created.$d));
        return dayjs(a.created.$d) - dayjs(b.created.$d);
      },
      defaultSortOrder: "descend",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <DateFilterDropDown
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
          />
        );
      },
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? "#1677ff" : undefined,
          }}
        />
      ),
      onFilter: (value, record) => {
        return record.created.$d
          ? dayjs(record.created.$d).isBetween(value[0], value[1], "day", [])
          : "";
      },
    },
    {
      title: "Клієнт",
      dataIndex: "clientName",
      sorter: (a, b) => {
        return a.clientName.localeCompare(b.clientName);
      },
      key: "client",
      width: "35%",
      ...getColumnSearchProps("clientName"),
    },
    {
      title: "Сума",
      dataIndex: "sum",
      width: "20%",
      key: "sum",
      render: (_, record) => {
        return record.sum.toFixed(2);
      },
      sorter: (a, b) => a.sum - b.sum,
    },
    {
      title: <CloudUploadOutlined />,
      dataIndex: "exported",
      key: "exported",
      width: "10%",
      render: (_, record) => {
        return (
          <Checkbox
            disabled
            checked={record.exported === "true" ? true : false}
          ></Checkbox>
        );
      },
    },
  ];

  useEffect(() => {
    setOrdersToTable();
  }, []);

  const setOrdersToTable = async () => {
    let orders = await getAllRecords(TABLES.ORDERS);
    const ordersToTable = await getFormattedDocuments(orders);

    setOrders(ordersToTable);
    setLoading(false);
  };

  const onRowAction = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setIsModalActionsOpen(true);
        // setDocumentId(record.id);
        // setDocumentNumber(rowIndex + 1);
        setDocument(record);
      },
    };
  };

  const afterExportFunction = async () => {
    if (!exportSuccess) {
      return;
    }
    message.success("Всі документи успішно відправлено!");
    const exportedDocuments = documentsToExport.map((exportedDoc) => {
      return { ...exportedDoc, exported: "true" };
    });

    const updated = await updateRecords(TABLES.ORDERS, exportedDocuments);
    if (!updated) {
      return message.error("Помилка оновлення документів");
    }

    const notExportedDocuments = filterNotExportedDocuments(
      exportedDocuments,
      orders
    );

    setOrders(
      await getFormattedDocuments([
        ...notExportedDocuments,
        ...exportedDocuments,
      ])
    );
  };

  const handleExportTodayDocuments = async () => {
    const todayDocuments = await getRecords(
      TABLES.ORDERS,
      "date",
      dayjs().format("DD.MM.YY")
    );
    if (!todayDocuments.length)
      return message.info("Немає даних для відправки");
    setDocumentsToExport(todayDocuments.reverse());
    setIsModalExportOpen(true);
  };

  const handleDownloadTodayDocuments = async () => {
    const todayDocuments = await getRecords(
      TABLES.ORDERS,
      "date",
      dayjs().format("DD.MM.YY")
    );

    if (!todayDocuments.length)
      return message.info("Немає даних для завантаження");
    createOrdersXMLFromDocumentsArray(todayDocuments);
  };

  const handleDownloadUnexported = async () => {
    const unexportedDocuments = await getRecords(
      TABLES.ORDERS,
      "exported",
      "false"
    );

    if (!unexportedDocuments.length)
      return message.info("Немає даних для завантаження");
    createOrdersXMLFromDocumentsArray(unexportedDocuments);
  };

  const handleExportUnexported = async () => {
    const unexportedDocuments = await getRecords(
      TABLES.ORDERS,
      "exported",
      "false"
    );

    if (!unexportedDocuments.length)
      return message.info("Немає даних для відправки");
    setDocumentsToExport(unexportedDocuments.reverse());
    setIsModalExportOpen(true);
  };

  const handleDeleteExported = async () => {
    const documentsToDelete = await getRecords(
      TABLES.ORDERS,
      "exported",
      "true"
    );

    if (!documentsToDelete.length) {
      return message.info("Немає документів для видалення");
    }

    deleteDocuments(documentsToDelete);
  };

  const deleteDocuments = async (documentsToDelete) => {
    const isSuccessDeleted = await Promise.all(
      documentsToDelete.map(async (doc) => {
        await deleteRecord(TABLES.ORDERS, doc.id);
      })
    );

    if (isSuccessDeleted) {
      const newDocuments = orders.filter(
        (document) => document.exported !== "true"
      );

      const formattedDocuments = await getFormattedDocuments(newDocuments);
      setOrders(formattedDocuments);
      message.success("Відправлені документи видалено!");
    }
  };

  const deftabs = [
    {
      label: (
        <span>
          <OrderedListOutlined />
          Замовлення
        </span>
      ),
      key: "1",
      children: <></>,
    },
  ];

  const deleteItems = [
    {
      label: "Видалити відправлені",
      key: "1",
      onClick: handleDeleteExported,
    },
  ];
  const exportItems = [
    {
      label: "Відправити сьогоднішні",
      key: "1",
      onClick: handleExportTodayDocuments,
    },
    {
      label: "Відправити невідправлені",
      key: "2",
      onClick: handleExportUnexported,
    },
    {
      label: "Завантажити сьогоднішні",
      key: "3",
      onClick: handleDownloadTodayDocuments,
    },
    {
      label: "Завантажити невідправлені",
      key: "4",
      onClick: handleDownloadUnexported,
    },
  ];

  const deleteOperations = (
    <Dropdown.Button menu={{ items: deleteItems }}>
      <DeleteOutlined />
    </Dropdown.Button>
  );
  const exportOperations = (
    <Dropdown.Button
      onClick={() => {
        handleExportUnexported();
      }}
      menu={{ items: exportItems }}
    >
      <CloudUploadOutlined />
    </Dropdown.Button>
  );

  const OperationsSlot = {
    left: deleteOperations,
    right: exportOperations,
  };

  return (
    <>
      <Tabs
        tabBarStyle={{ marginBottom: "5px" }}
        centered
        tabBarExtraContent={OperationsSlot}
        defaultActiveKey="1"
        size={"middle"}
        items={deftabs}
      />
      <Row style={{ justifyContent: "center" }}>
        <Col span={24}>
          <Table
            loading={loading}
            pagination={{ position: ["bottomCenter"], hideOnSinglePage: true }}
            onRow={onRowAction}
            columns={columns}
            dataSource={orders}
            onChange={onChange}
            tableLayout="fixed"
            className="ordersTable"
            size="middle"
            title={() => {
              const totals = getOrdersTotals(orders);
              return (
                <TableTotals
                  quantity={totals.quantity.toFixed(2)}
                  total={totals.total.toFixed(2)}
                  // currency={props?.currency}
                />
              );
            }}
          />
          <FloatButton
            size="large"
            type="primary"
            style={{ position: "fixed", bottom: "20px", right: "24px" }}
            onClick={() => navigate("create")}
            icon={<PlusCircleOutlined />}
          ></FloatButton>
        </Col>
        <ModalActions
          // documentId={documentId}
          // setDocumentId={setDocumentId}
          // documentNumber={documentNumber}
          setIsModalActionsOpen={setIsModalActionsOpen}
          isModalActionsOpen={isModalActionsOpen}
          setDocuments={setOrders}
          documents={orders}
          document={document}
          setDocument={setDocument}
          setIsModalCreateIcoOpen={setIsModalCreateIcoOpen}
        ></ModalActions>
        <ModalCreateIco
          isModalCreateIcoOpen={isModalCreateIcoOpen}
          setIsModalCreateIcoOpen={setIsModalCreateIcoOpen}
          documentSource={{
            ...document,
            docDebt: document?.sum,
            docGuid: document?.documentId,
          }}
        ></ModalCreateIco>
        <ExportModal
          setIsExportSuccess={setIsExportSuccess}
          afterExportFunction={afterExportFunction}
          isModalExportOpen={isModalExportOpen}
          setIsModalExportOpen={setIsModalExportOpen}
          documents={documentsToExport}
          documentType={"order"}
        ></ExportModal>
        <BackTo to={"/"}></BackTo>
      </Row>
    </>
  );
}
export default Orderspage;
